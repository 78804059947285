/* eslint-disable no-unused-vars */

'use strict';

var React = require('react');
var ReactDOM = require('react-dom');
var OrdersStore = require('../../apps/core/modules/finance/orders/store');
var OrdersReducer = require('../../apps/core/modules/finance/orders/reducer');
var Orders = require('../../apps/core/modules/finance/orders/index');
var HTTPService = require('../../services/HTTPService');
var OrderModel = require('../../models/Order');
var PropertyMetadataModel = require('../../models/PropertyMetadata');
var PropertyMetadata = require('../../models/PropertyMetadata');

Wethod.module('FinanceApp', function (FinanceApp, Wethod, Backbone, Marionette, $, _) {
  // CONTROLLER
  // smisto le richieste ai moduli controllandone i permessi se l'utente non
  // ha i permessi per il modulo gestisco l'eccezione
  FinanceApp.Controller = {
    showOrder: function (options) {
      var type = { app: 'orders' };
      var orderAuthorizationRequest = Wethod.request('get:finance:authorization', type);
      $.when(orderAuthorizationRequest)
        .done(function (orderAuthorizationResponse) {
          var permissionDeniedView;
          if (_.isUndefined(orderAuthorizationResponse)) {
            permissionDeniedView = new Wethod.PermissionDenied.PermissionDeniedTemplateView();
            Wethod.regions.body.show(permissionDeniedView);
            return;
          }

          Wethod.FinanceApp.authorization = orderAuthorizationResponse;

          if (Wethod.FinanceApp.getPermission('view')) {
            var supplierStatusEnabledRequest = Wethod.request('get:company:supplier-status:enabled');
            var purchaseCodeEditableRequest = Wethod.request('get:finance:order:purchase-code-editable');
            var contactsRequest = Wethod.request('get:company:order:contact');
            $.when(supplierStatusEnabledRequest, purchaseCodeEditableRequest, contactsRequest,
              OrderModel.getAvailableStatuses(),
              PropertyMetadataModel.list(PropertyMetadataModel.ORDER_ENTITY))
              .done(function (supplierStatusEnabled, purchaseCodeEditable, contacts, statuses,
                orderMetadata) {
                Wethod.FinanceApp.supplierStatusEnabled = supplierStatusEnabled.get('status_enabled');
                Wethod.FinanceApp.purchaseCodeEditable = purchaseCodeEditable.get('editable');

                // instantiate the layout
                var layout = new FinanceApp.StructureLayoutView();

                // renderizzo il template del modulo
                layout.render();

                var reducerWrapper = new OrdersReducer({
                  keyword: Wethod.FinanceApp.Controller.getOrdersFilters(options),
                  contacts: contacts.toJSON(),
                  availableStatuses: statuses,
                  orderMetadata: orderMetadata,
                });

                var store = OrdersStore(reducerWrapper.reduxReducer);
                var element = React.createElement(Orders, { store: store });
                var container = document.getElementById('financeRoot');
                if (container !== null) {
                  ReactDOM.render(element, container);
                }
              });
          } else {
            permissionDeniedView = new Wethod.PermissionDenied.PermissionDeniedTemplateView();
            Wethod.regions.body.show(permissionDeniedView);
          }
        });
    },
    // coming here from the main router, this function fetches permissions
    // from the api, checks them, and initialize the module view
    showInvoice: function (data) {
      var authorizationRequest = Wethod.request('get:finance:authorization', { app: 'invoices' });
      var contactsRequest = Wethod.request('get:company:invoice:contact');
      var vatRateRequest = Wethod.request('get:company:vat-rate', false); // Do not include archived
      var paymentTermsRequest = Wethod.request('get:company:payment-term', false); // Do not include archived
      var isInvoiceNumberAutomaticRequest = Wethod.request('get:finance:invoice:automatic:number');
      var isExportEnabledRequest = Wethod.request('get:finance:invoice:export:enabled');
      var propertyMetadataRequest = PropertyMetadata.list(PropertyMetadataModel.INVOICE_ENTITY);
      $.when(authorizationRequest, contactsRequest, isInvoiceNumberAutomaticRequest,
        isExportEnabledRequest, vatRateRequest, paymentTermsRequest, propertyMetadataRequest)
        .done(function (authorization, contacts, isInvoiceNumberAutomatic,
          isExportEnabled, vatRates, paymentTerms, propertyMetadata) {
          Wethod.FinanceApp.authorization = authorization;
          if (_.isUndefined(Wethod.FinanceApp.authorization) || !Wethod.FinanceApp.getPermission('view')) {
            var permissionDeniedView = new Wethod.PermissionDenied.PermissionDeniedTemplateView();
            Wethod.regions.body.show(permissionDeniedView);
            return;
          }
          var params = {
            filter: data,
            contacts: contacts,
            vatRates: vatRates,
            paymentTerms: paymentTerms,
            propertyMetadata: propertyMetadata,
          };

          Wethod.FinanceApp.isInvoiceNumberAutomatic = isInvoiceNumberAutomatic.get('enabled');
          Wethod.FinanceApp.isExportEnabled = isExportEnabled.get('enabled');

          var layout = new FinanceApp.StructureLayoutView();
          Wethod.FinanceApp.Invoice.Init(layout, params);
        });
    },
    prepareInvoice: function (invoiceData) {
      var sentence = Wethod.Utility.randomSentence(Wethod.sentencesDays, Wethod.sentencesSongs);
      var loadingView = new Wethod.View.Loading.BigView({ sentence: sentence });
      Wethod.regions.body.show(loadingView);

      var authorizationRequest = Wethod.request('get:finance:authorization', { app: 'invoices' });
      var contactsRequest = Wethod.request('get:company:invoice:contact');
      var vatRateRequest = Wethod.request('get:company:vat-rate', false); // Do not include archived
      var paymentTermsRequest = Wethod.request('get:company:payment-term', false); // Do not include archived
      var isInvoiceNumberAutomaticRequest = Wethod.request('get:finance:invoice:automatic:number');
      var isExportEnabledRequest = Wethod.request('get:finance:invoice:export:enabled');
      var propertyMetadataRequest = PropertyMetadata.list(PropertyMetadataModel.INVOICE_ENTITY);

      $.when(authorizationRequest, contactsRequest, isInvoiceNumberAutomaticRequest,
        isExportEnabledRequest, vatRateRequest, paymentTermsRequest, propertyMetadataRequest)
        .done(function (authorization, contacts, isInvoiceNumberAutomatic,
          isExportEnabled, vatRates, paymentTerms, propertyMetadata) {
          Wethod.FinanceApp.authorization = authorization;
          if (_.isUndefined(Wethod.FinanceApp.authorization) || !Wethod.FinanceApp.getPermission('view')) {
            var permissionDeniedView = new Wethod.PermissionDenied.PermissionDeniedTemplateView();
            Wethod.regions.body.show(permissionDeniedView);
            return;
          }

          if (invoiceData && invoiceData.length > 0) {
            console.log('invoiceData', invoiceData);
            invoiceData = invoiceData.map(function (invoice) {
              if (!invoice.purchase_order && invoice.project.client_po) {
                invoice.purchase_order = invoice.project.client_po;
              }
              // Set client vat rate when it is a valid one
              if (!invoice.vat_rate && invoice.client.vat_rate) {
                if (!invoice.client.vat_rate.is_archived) {
                  invoice.vat_rate = invoice.client.vat_rate;
                }
              }
              // Set client payment term when it is a valid one
              if (!invoice.payment_term && invoice.client.payment_term) {
                if (!invoice.client.payment_term.is_archived) {
                  invoice.payment_term = invoice.client.payment_term;
                }
              }
              return invoice;
            });
          }

          var layout = new FinanceApp.StructureLayoutView();
          Wethod.FinanceApp.Invoice.Init(layout, {
            invoice: invoiceData,
            contacts: contacts,
            vatRates: vatRates,
            paymentTerms: paymentTerms,
            propertyMetadata: propertyMetadata,
          });

          Wethod.FinanceApp.isInvoiceNumberAutomatic = isInvoiceNumberAutomatic.get('enabled');
          Wethod.FinanceApp.isExportEnabled = isExportEnabled.get('enabled');
        });
    },
    showClient: function (options) {
      // instantiate the layout
      var layout = new FinanceApp.StructureLayoutView();
      Wethod.FinanceApp.Client.InitClient(layout, options.search);
    },
    showPersonClient: function (id) {
      var layout = new FinanceApp.StructureLayoutView();
      Wethod.FinanceApp.Client.InitPersonClient(layout, id);
    },
    showCompanyClient: function (id) {
      var layout = new FinanceApp.StructureLayoutView();

      Wethod.FinanceApp.Client.InitCompanyClient(layout, id);
    },
    showSuppliers: function () {
      var options = { app: 'suppliers' };
      var suppliersAuthorizationRequest = Wethod.request('get:finance:authorization', options);
      $.when(suppliersAuthorizationRequest).done(function (suppliersAuthorizationResponse) {
        Wethod.FinanceApp.authorization = suppliersAuthorizationResponse;
        var layout = new FinanceApp.StructureLayoutView();
        Wethod.FinanceApp.Supplier.InitSupplier(layout);
      });
    },
    showSupplierDetail: function (id) {
      var options = { app: 'suppliers' };
      var suppliersAuthorizationRequest = Wethod.request('get:finance:authorization', options);
      $.when(suppliersAuthorizationRequest).done(function (suppliersAuthorizationResponse) {
        Wethod.FinanceApp.authorization = suppliersAuthorizationResponse;
        var layout = new FinanceApp.StructureLayoutView();
        Wethod.FinanceApp.Supplier.InitSupplierDetail(layout, id);
      });
    },
    getOrdersFilters: function (queryString) {
      if (!Number.isNaN(Number(queryString)) && queryString) {
        return 'id:' + queryString;
      }
      if (HTTPService.getQueryParamByKey(queryString, 'project')) {
        return 'project:' + HTTPService.getQueryParamByKey(queryString, 'project');
      }
      return '';
    },
  };
});
