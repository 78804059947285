/* eslint-disable no-shadow,default-case,camelcase,no-unused-vars */

'use strict';

var FeatureService = require('../../../services/FeatureService');

Wethod.module('ReportApp.People', function (People, Wethod, Backbone, Marionette, $, _, Header) {
  var structureTemplate;
  var filter = 'internal';

  var resetData = function () {
    structureTemplate = undefined;
    filter = 'internal';
  };

  /**
   * Returns the max amount of hours tracked by one of the given employees.
   * @param employees
   * @return {number}
   */
  var getMaxTimetrackingHours = function (employees) {
    var maxHours = 0;
    for (var i = 0; i < employees.length; i++) {
      var employee = employees[i];
      var weeklyHours = 0;
      for (var j = 0; j < employee.timetrackings.length; j++) {
        var timetracking = employee.timetrackings[j];
        weeklyHours += parseFloat(timetracking.hours);
      }
      if (weeklyHours > maxHours) {
        maxHours = weeklyHours;
      }
    }
    return maxHours;
  };

  var showBody = function () {
    var loadingTemplate = new People.LoadingMoreDataTemplateView();
    structureTemplate.body.show(loadingTemplate);

    var options = { date: People.monday };

    var reportTimetrackingRequest = Wethod.request('get:report:timetracking', options);
    $.when(reportTimetrackingRequest).done(function (reportTimetrackingResponse) {
      // aggrego tutti i dati utili alla visualizzazione di un employee
      var hours;
      var avaiableHours;
      var projectTypeTrackings;
      var filteredEmployeesList = reportTimetrackingResponse.get('employees');

      filteredEmployeesList = _.filter(filteredEmployeesList, function (employee) {
        return (filter === 'external' && employee.is_external)
          || (filter === 'internal' && !employee.is_external)
          || (filter === 'all');
      });

      var maxHours = getMaxTimetrackingHours(filteredEmployeesList);

      _.each(filteredEmployeesList, function (employee) {
        hours = 0;
        projectTypeTrackings = {};

        _.each(employee.timetrackings, function (timetracking) {
          if (!_.isNull(timetracking.project_id)) {
            if (!_.isEmpty(timetracking)) {
              // associo al timetracking dell'employee il nome del progetto
              timetracking.project_name = _.findWhere(reportTimetrackingResponse.get('project'), { id: timetracking.project_id }).name;
              timetracking.project_job_order = _.findWhere(reportTimetrackingResponse.get('project'), { id: timetracking.project_id }).job_order;

              if (timetracking.project_job_order === null) {
                timetracking.project_job_order = '&nbsp;';
              }
            }

            if (!_.isObject(projectTypeTrackings[timetracking.project_type_id])) {
              if (timetracking.hours !== 0) {
                var hours = parseFloat(timetracking.hours);
                projectTypeTrackings[timetracking.project_type_id] = {};
                projectTypeTrackings[timetracking.project_type_id].hours = hours;

                // ottengo il attributi del projecttype
                _.each(reportTimetrackingResponse.get('project_types'), function (project_type) {
                  if (project_type.id === timetracking.project_type_id) {
                    projectTypeTrackings[timetracking.project_type_id].color = project_type.color;
                    projectTypeTrackings[timetracking.project_type_id].name = project_type.name;
                    projectTypeTrackings[timetracking.project_type_id]
                      .chargeable = project_type.chargeable;
                    projectTypeTrackings[timetracking.project_type_id].sort = project_type.sort;
                  }
                });
              }
            } else {
              projectTypeTrackings[timetracking.project_type_id]
                .hours += parseFloat(timetracking.hours);
            }
          }
        });

        _.each(projectTypeTrackings, function (typeTracking) {
          avaiableHours = 0;
          typeTracking.width = (typeTracking.hours / maxHours) * 100;
          typeTracking.displayHours = typeTracking.hours;
          hours += typeTracking.hours;
        });

        employee.projectTypeTrackings = _.sortBy(projectTypeTrackings, function (projectType) {
          return projectType.sort;
        });

        employee.timetrackings = _.sortBy(employee.timetrackings, function (timetracking) {
          return -parseFloat(timetracking.hours);
        });
      });

      // lista employee
      var employees = _.sortBy(filteredEmployeesList, function (employee) {
        return employee.name.toLowerCase();
      });
      var employeesCollection = new People.EmployeesCollection(employees);
      var employeesTemplate = new People.EmployeeRowsCollectionView({
        collection: employeesCollection,
      });

      // lista project type
      var projectTypes = _.sortBy(reportTimetrackingResponse.get('project_types'), function (projectType) {
        return [-projectType.chargeable, projectType.name.toLowerCase()];
      });

      var projectTypesCollection = new People.ProjectTypesCollection(projectTypes);
      var rojectTypesTemplate = new People.ProjectTypeRowsCollectionView({
        collection: projectTypesCollection,
      });

      structureTemplate.navigate.currentView.$el.removeClass('disabled');
      structureTemplate.body.show(employeesTemplate);
      structureTemplate.projectTypeList.show(rojectTypesTemplate);
      structureTemplate.setListHeight();
    });
  };

  People.changeWeek = function (action, viewObj) {
    var start;
    var end;

    viewObj.$el.addClass('disabled');

    switch (action) {
      case 'next':
        People.monday = moment(People.monday).add(7, 'days').format('YYYY-MM-DD');
        break;
      case 'previous':
        People.monday = moment(People.monday).subtract(7, 'days').format('YYYY-MM-DD');
        break;
    }

    start = moment(People.monday).startOf('isoWeek').format('DD/MM');
    end = moment(People.monday).startOf('isoWeek').add(4, 'day').format('DD/MM');

    viewObj.ui.weekLabel.text(start + ' - ' + end);

    showBody();
  };

  this.filterLevelTypeEmployee = function (levelFilter) {
    filter = levelFilter;
    showBody();
  };

  this.showEmployeeDetail = function (viewObj) {
    var detailView = new People.EmployeeRowDetailView({
      model: viewObj.model,
    });
    structureTemplate.details.show(detailView);

    var elem = document.getElementsByClassName('reportPeopleDetails');
    elem[0].scrollIntoView(true);
  };

  // EXPORT
  this.showModalExport = function () {
    var modalExport = new People.ModalExportLayoutView();
    structureTemplate.modal.show(modalExport);

    this.showModalTimesheetExport(modalExport);
  };
  this.showModalTimesheetExport = function (modalView) {
    var modalBody = new People.ModalExportTimesheetBodyLayoutView();
    modalView.body.show(modalBody);
  };
  this.showModalWagesExport = function (modalView) {
    var modalBody = new People.ModalExportWagesBodyLayoutView();
    modalView.body.show(modalBody);
  };
  this.showModalFoodStampsExport = function (modalView) {
    var modalBody = new People.ModalExportFoodStampsBodyLayoutView();
    modalView.body.show(modalBody);
  };
  this.doModuleExport = function (viewObj, selectedOption, selectedDate) {
    var exportRequest;

    switch (selectedOption) {
      case 'timesheet':
        var options = {
          month: selectedDate,
        };
        exportRequest = Wethod.request('get:timesheet:export', options);
        break;
      case 'wages':
        exportRequest = Wethod.request('get:timesheet:payslips:export', selectedDate);
        break;
      case 'food-stamps':
        exportRequest = Wethod.request('get:timesheet:meal-vouchers:export', selectedDate);
        break;
      default:
        break;
    }
    $.when(exportRequest)
      .done(function () {
        viewObj.destroy();
      }).fail(function (error) {
        this.showModalError(error);
      }.bind(this));
  };
  this.showModalError = function (error) {
    var errorText = error || 'This export is currently not available.';
    var model = new Backbone.Model({
      errorText: errorText,
    });
    var modalError = new People.ModalErrorItemView({ model: model });
    structureTemplate.modal.show(modalError);
  };

  People.Controller = {
    showPeople: function () {
      resetData();

      var loadingView = new Wethod.View.Loading.BigView();
      Wethod.regions.body.destroy().show(loadingView);

      var reportAuthorizationRequest = Wethod.request('get:report:authorization');
      var timesheetPermissionRequest = Wethod.request('get:friday:authorization', { app: 'time_tracking' });
      $.when(reportAuthorizationRequest, timesheetPermissionRequest)
        .done(function (reportAuthorizationResponse, timesheetPermissionResponse) {
          if (_.isUndefined(reportAuthorizationResponse)) {
            return;
          }

          Wethod.ReportApp.authorization = reportAuthorizationResponse;
          Wethod.ReportApp.timesheetAuthorization = timesheetPermissionResponse;

          if (Wethod.ReportApp.getPermission('view_timesheet')) {
            // TEMPLATE
            // app structure
            var thisMonday = moment().day('Monday').format('YYYY-MM-DD');
            People.monday = moment(thisMonday).subtract(7, 'days').format('YYYY-MM-DD');

            // STRUTTURA APP PLANNING
            structureTemplate = new People.StructureLayoutView();
            var changeWeekTemplate = new People.ChangeWeekItemView({
              start: moment(People.monday).startOf('isoWeek').format('DD/MM'),
              end: moment(People.monday).startOf('isoWeek').add(4, 'day').format('DD/MM'),
            });
            var sectionHeaderModel = new Header.SectionHeaderModel({
              current_section: 'Weekly Timesheet',
              preview_anchor_id: 'timesheets',
              helper_url: 'reports/index/#timesheets',
            });
            var timesheetHeaderRegion = new Header.SectionHeaderView({ model: sectionHeaderModel });

            structureTemplate.render();
            structureTemplate.headerSection.show(timesheetHeaderRegion);

            structureTemplate.navigate.show(changeWeekTemplate);

            showBody();
          } else {
            loadingView.destroy();
            var permissionDeniedView = new Wethod.PermissionDenied.PermissionDeniedTemplateView();
            Wethod.regions.body.show(permissionDeniedView);
          }
        });
    },
  };
}, Wethod.module('HeaderApp.Header'));
