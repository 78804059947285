const React = require('react');
const SectionHeader = require('../../../../../../../common/react/SectionHeader/components/SectionHeader.react');
const WeekSelector = require('../../../../../../../common/react/date-selectors/WeekSelector.react');
const ToggleButton = require('../../../../../../../common/react/inputs/Switch/Switch.react');
const BuFilter = require('../../../../../../../common/react/BusinessUnitSelectFilter/BusinessUnitSelectFilter.react');
const EmployeeDetails = require('../containers/EmployeeDetails');
const DateService = require('../../../../../../../services/DateService');

const Actions = ({
  isWaiting,
  date,
  changeDate,
  internationalCalendar,
  periodicity,
  dailyModeToggle,
  updatePeriodicity,
  buEnabled,
  buFilter,
  changeBusinessUnit,
  userId,
  employeeId,
}) => {
  function handleDateChange(day) {
    changeDate(moment(day).format('YYYY-MM-DD'), userId);
  }

  function handleToggleClick() {
    if (!isWaiting) {
      const updatedPeriodicity = periodicity === 'weekly' ? 'daily' : 'weekly';
      updatePeriodicity(updatedPeriodicity, userId);
    }
  }

  function getHolidayForMonth(month) {
    return DateService.getHolidayForMonthRequest(month, employeeId);
  }

  const hasEmployee = () => (!!userId);

  const isToggleVisible = () => periodicity != null && dailyModeToggle !== 'force-daily';

  const getToggleStyle = () => {
    let style = 'timesheet-weekly__periodicity';
    if (isWaiting) {
      style += ' disabled';
    }
    return style;
  };

  const getToggle = () => {
    const label = <span><span>Daily</span><span className="hidden-mobile">Mode</span></span>;

    if (isToggleVisible()) {
      return (
        <div className={getToggleStyle()}>
          <ToggleButton enabled={periodicity === 'daily'}
            label={label}
            name="periodicity"
            id="timesheet-weekly-periodicity"
            checked={periodicity === 'daily'}
            onChange={handleToggleClick} />
        </div>
      );
    }
    return null;
  };

  const getBuFilter = () => {
    if (buEnabled) {
      return (
        <div className="timesheet-weekly__bu-select">
          <BuFilter onApply={changeBusinessUnit} selectedValues={buFilter} />
        </div>
      );
    }
    return null;
  };

  const getEmployeeDetails = () => {
    if (hasEmployee()) {
      return (
        <EmployeeDetails />
      );
    }
    return null;
  };

  return (
    <div className="timesheet-weekly__header wethod-section-actions">
      <SectionHeader
        current_section="Timesheet"
        preview_anchor_id="timesheet"
        tour_id={74340}
        tour_start_page="timesheet"
        helper_url="friday/index/#timesheet" />
      <div className="timesheet-weekly__actions">
        <div className="timesheet-weekly__actions--left">
          {getBuFilter()}
          <div className="timesheet-weekly__week-selector">
            <WeekSelector
              internationalCalendar={internationalCalendar}
              date={date}
              getHolidays={getHolidayForMonth}
              changeDate={handleDateChange}
              isWaiting={isWaiting} />
          </div>
        </div>
        {getToggle()}
      </div>
      {getEmployeeDetails()}
    </div>
  );
};

module.exports = Actions;
