const { connect } = require('react-redux');
const Component = require('../components/Items.react');
const actions = require('../actions');
const RequestService = require('../services/RequestService');

const mapStateToProps = (state) => ({
  type: state.type,
  items: state.items,
  hasMore: state.hasMorePages,
  pageOffset: state.pageOffset,
  pageLimit: state.pageLimit,
  sortBy: state.sortBy,
  isWaiting: RequestService.isWaitingForItems(state.waitingFor),
  bu: state.buFilter,
  filters: state.filters,
  search: state.search,
  winProbabilityThreshold: state.winLossProbabilityThreshold.winProbabilityThreshold,
});

const mapDispatchToProps = (dispatch) => ({
  getItems: (type, offset, limit, filters, search, orderBy, sort, bu,
    winProbabilityThreshold) => dispatch(actions
    .getItems(type, offset, limit, filters, search, orderBy, sort, bu, winProbabilityThreshold)),
  showSidebar: (item, winProbabilityThreshold) => dispatch(actions
    .showSidebar(item, winProbabilityThreshold)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
