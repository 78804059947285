const React = require('react');
const Card = require('./Card.react');
const Body = require('./CardBody.react');
const IconButton = require('../../../../../common/react/TooltipFixed/IconButtonTooltip.react');
const { round } = require('../../../../../services/MathService');

module.exports = class CalendarCard extends React.Component {
  static isDirty(value) {
    return value != null && value.toString().trim() !== '' && value !== 0;
  }

  static hasChanged(oldVal, newVal) {
    return (CalendarCard.isDirty(oldVal) || CalendarCard.isDirty(newVal))
      && (oldVal !== newVal);
  }

  static formatPmForInvoice(pm) {
    return pm ? {
      id: pm.id,
      pm_name: pm.person.name,
      pm_surname: pm.person.surname,
    } : {
      id: null,
      pm_name: null,
      pm_surname: null,
    };
  }

  constructor(props) {
    super(props);

    this.state = {};
  }

  handleNotesClick() {
    this.props.showNotesModal(this.props.plan, this.props.date);
  }

  handlePlanChange(value) {
    const oldPlan = this.props.plan || {};
    const budgetRate = this.props.currency ? this.props.currency.exchange_rate : undefined;
    let rate = 1;
    if (this.props.currency) {
      const month = moment(this.props.date).format('YYYY-MM');
      const invoices = this.props.invoices ? this.props.invoices[month] : null;
      rate = invoices && invoices.length > 0
        ? this.props.calcInvoicePlanRate(invoices, value)
        : this.props.currency.default_exchange_rate;
    }
    const newValue = this.getOriginalValue(value, rate);
    const updatedPlan = { ...oldPlan, amount: newValue };
    if (budgetRate && rate !== budgetRate) { // Update exchange rate when necessary
      updatedPlan.exchange_rate = rate;
    }
    this.props.updatePlan(updatedPlan, this.props.date);
  }

  handleCreateInvoiceClick() {
    const deltaInvoiced = this.getInvoicedDelta();

    if (deltaInvoiced) {
      const rate = this.props.currency ? this.props.currency.default_exchange_rate : 1;
      const value = deltaInvoiced / rate;
      const account = this.props.project.account ? this.props.project.account : { id: null };
      const pm = CalendarCard.formatPmForInvoice(this.props.project.pm);
      const notes = this.props.plan?.notes;
      const data = {
        value,
        note: notes,
        date: this.props.date,
        id_project: this.props.project.id,
        id_client: this.props.project.client.id,
        client: {
          id: this.props.project.client.id,
          corporate_name: this.props.project.client.corporate_name,
          acronym: this.props.project.client.acronym,
          vat_rate: this.props.project.client.vat_rate,
          payment_term: this.props.project.client.payment_term,
        },
        currency: this.props.currency,
        exchange_rate: this.props.currency ? this.props.currency.default_exchange_rate : null,
        project: { ...this.props.project, account, pm },
      };
      dispatcher.trigger('url:invoice:show', [data]);
    }
  }

  /**
   * Get value converted to master currency and formatted as K
   * @param value
   * @param rate
   */
  getOriginalValue(value, rate) {
    const val = value || 0;
    return this.props.convertToK(this.props.convertToMaster(val, rate, this.props.currency));
  }

  /**
   * Get value converted to budget currency and formatted as unit
   * @param value
   * @param rate
   */
  getVisibleValue(value, rate) {
    const val = value || 0;
    const unitVal = this.props.convertToUnit(val);
    const planRate = this.props.currency ? rate || this.props.currency.default_exchange_rate : 1;
    const currencyVal = this.props.convertToCurrency(unitVal, planRate, this.props.currency);
    return round(currencyVal, 2);
  }

  getInvoiced() {
    let invoiced = 0;
    const month = moment(this.props.date).format('YYYY-MM');
    if (this.props.invoices && month in this.props.invoices) {
      const invoices = this.props.invoices[month];
      invoiced = invoices
        .reduce((total, invoice) => {
          const rate = this.props.currency
            ? invoice.rate || this.props.currency.default_exchange_rate
            : 1;
          return total + this.props.convertToCurrency(
            invoice.value, rate, this.props.currency,
          );
        },
        invoiced);
    }
    return invoiced;
  }

  // Get amount of monthly plan formatted as unit and converted to budget currency
  getPlanned() {
    const planned = this.props.plan || {};
    const budgetExchangeRate = this.props.project.budget
      ? this.props.project.budget.exchange_rate : null;
    const exchangeRate = this.props.planMode === 0
      ? budgetExchangeRate : planned.exchange_rate;
    return this.getVisibleValue(planned.amount, exchangeRate);
  }

  /**
   * Get difference between monthly planned and invoiced amounts.
   * @returns {number}
   */
  getInvoicedDelta() {
    return round(this.getPlanned() - this.getInvoiced(), 2);
  }

  getNotes() {
    return this.props.plan ? this.props.plan.notes : null;
  }

  getMonth() {
    const month = moment(this.props.date);
    return month.isValid() ? month.format('MMMM YYYY') : moment().format('MMMM YYYY');
  }

  getNotesButton() {
    if (this.props.canEdit && this.hasPlan()) {
      const notes = this.getNotes();
      let notesIcon = 'wethod-icon wethod-icon-notes wethod-icon-notes--black';
      if (CalendarCard.isDirty(notes)) notesIcon += ' wethod-icon-notes--done';
      return (
        <IconButton label="Notes"
          borderless
          onClick={this.handleNotesClick.bind(this)}>
          <div className={notesIcon} />
        </IconButton>
      );
    }
    return null;
  }

  getCurrencyCode() {
    return this.props.currency ? this.props.currency.code : null;
  }

  hasPlan() {
    return CalendarCard.isDirty(this.getPlanned());
  }

  isCompact() {
    return !CalendarCard.isDirty(this.getInvoiced())
      && !this.hasPlan();
  }

  render() {
    return (
      <div className="invoice-plan__calendar-card">
        <Card title={this.getMonth()}
          actions={this.getNotesButton()}
          className="invoice-plan__calendar-card-month">
          <Body canEdit={this.props.canEdit && !this.props.isWaiting}
            compact={this.isCompact()}
            invoiced={this.getInvoiced()}
            planned={this.getPlanned()}
            deltaInvoiced={this.getInvoicedDelta()}
            invoices={this.props.invoices}
            currencyCode={this.getCurrencyCode()}
            onChange={this.handlePlanChange.bind(this)}
            createInvoice={this.handleCreateInvoiceClick.bind(this)} />
        </Card>
      </div>
    );
  }
};
