const React = require('react');
const PropTypes = require('prop-types');
const Radio = require('./Radio.react');
const Accordion = require('../../Accordion/Accordion.react');

/**
 * A radio button collapsible component with visible text and hidden body, showed when expanded.
 * It expands and collapses on click on the dropdown icon.
 */
const RadioCollapsible = ({
  name, value, checked, onClick, title, subtitle, description, className, expandedBody,
}) => {
  const actualClassName = `wethod-radio-collapsible ${className}`;

  const label = (
    <span className="wethod-radio-collapsible__label">
      <span className="wethod-radio-collapsible__title">{title}</span>
      <span className="wethod-radio-collapsible__subtitle">{subtitle}</span>
      <span className="wethod-radio-collapsible__description">{description}</span>
    </span>
  );

  return (
    <div className={actualClassName}>
      <Radio
        name={name}
        value={value}
        checked={checked}
        onClick={onClick} />
      <Accordion label={label}>
        {expandedBody}
      </Accordion>
    </div>
  );
};

RadioCollapsible.defaultProps = {
  className: '',
  title: '',
  subtitle: '',
  description: '',
  expandedBody: null,
  checked: false,
  onClick: null,
};

RadioCollapsible.propTypes = {
  /**
   * The class to be added to the component style.
   */
  className: PropTypes.string,
  /**
   * Name given to the radio input: this is used to identify a group of radio buttons.
   */
  name: PropTypes.string.isRequired,
  /**
   * Value associated to the input.
   */
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  /**
   * Primary text shown in the element.
   */
  title: PropTypes.string,
  /**
   * Secondary text shown in the element.
   */
  subtitle: PropTypes.string,
  /**
   * Tertiary text shown in the element.
   */
  description: PropTypes.string,
  /**
   * Body shown when the element is expanded, hidden when collapsed.
   */
  expandedBody: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.node)]),
  /**
   * It says whether or not this radio button is the default-selected item in the group.
   */
  checked: PropTypes.bool,
  /**
   * Function to call when radio is clicked.
   * @param event {{}}
   */
  onClick: PropTypes.func,
};

module.exports = RadioCollapsible;
